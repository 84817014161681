<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Sale report</h1>
        <router-link to="/partners/sales" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </router-link>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="w600 mx-auto mb-20 py-50">
      <div class="grid">
        <div class="label">First Name</div>
        <div class="item">{{ saleReport.user.firstName }}</div>
        <div class="label">Last Name</div>
        <div class="item">{{ saleReport.user.lastName }}</div>
        <div class="label">Email</div>
        <div class="item">{{ saleReport.user.email }}</div>
        <div class="label">Order Number</div>
        <div class="item">{{ saleReport.invoice.invoiceNumber }}</div>
        <div class="label">Added On</div>
        <div class="item">{{ format(saleReport.license.createdAt) }}</div>
        <div class="label">Product</div>
        <div class="item">{{ saleReport.license.product.name }}</div>
        <div class="label">Status</div>
        <div class="item">{{ getStatus(saleReport) }}</div>
        <div class="label">Amount</div>
        <div class="item">
          {{ formatCurrency(saleReport.invoice.priceTotal) }}
        </div>
        <div class="label">Annual/Monthly</div>
        <div class="item">
          {{ sentenseCase(saleReport.license.product.billingType) }}
        </div>
        <div class="label">Account Active</div>
        <div class="item">
          {{ saleReport.license.isActive ? "Active" : "Inactive" }}
        </div>
      </div>
    </section>

    <section class="w600 mx-auto mt-20 row gap-20 space-between">
      <s-btn @click.native="generateReport" class="green"
        >Download / print</s-btn
      >
      <router-link to="/partners/sales" class="green">
        <s-btn class="green">close</s-btn>
      </router-link>
    </section>

    <vue-html2pdf
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Bank-Details-SecurCom"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <section class="w600 mx-auto mb-20 black--text pt-50">
          <div class="grid">
            <div class="label">First Name</div>
            <div class="item">{{ saleReport.user.firstName }}</div>
            <div class="label">Last Name</div>
            <div class="item">{{ saleReport.user.lastName }}</div>
            <div class="label">Email</div>
            <div class="item">{{ saleReport.user.email }}</div>
            <div class="label">Order Number</div>
            <div class="item">{{ saleReport.invoice.invoiceNumber }}</div>
            <div class="label">Added On</div>
            <div class="item">{{ format(saleReport.license.createdAt) }}</div>
            <div class="label">Product</div>
            <div class="item">{{ saleReport.license.product.name }}</div>
            <div class="label">Status</div>
            <div class="item">{{ getStatus(saleReport) }}</div>
            <div class="label">Amount</div>
            <div class="item">
              {{ formatCurrency(saleReport.invoice.priceTotal) }}
            </div>
            <div class="label">Annual/Monthly</div>
            <div class="item">
              {{ sentenseCase(saleReport.license.product.billingType) }}
            </div>
            <div class="label">Account Active</div>
            <div class="item">
              {{ saleReport.license.isActive ? "Active" : "Inactive" }}
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import dayjs from "dayjs";
export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {};
  },
  methods: {
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getStatus(item) {
      let msg = "Not Active";
      if (item.license.isExpired) msg = "Expired";
      if (item.license.isDeleted) msg = "Cancelled";
      if (item.invoice.isPaid) msg = "Active";
      if (item.license.isActive) msg = "Active";
      return msg;
    },
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    sentenseCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
  },
  computed: {
    ...mapState("payment", ["saleReport"]),
  },
};
</script>

<style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
  border-bottom: 1px solid #42515e;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
  border-top: 1px solid #42515e;
}
</style>